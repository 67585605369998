.icon {
  margin-left: 3px;
  margin-right: 1px;
  height: 1em;
  color: var(--grey-500);
}

.icon:hover {
  cursor: pointer;
  color: var(--blue-500);
}
