.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 20px;
}

.barWrapper {
  flex-grow: 1;
  height: 4px;
  position: relative;
  border-radius: 60px;
  overflow: hidden;
}

.bar {
  height: 100%;
  background-color: var(--blue-500);
  transition: width 0.6s ease-in-out;
  border-radius: 60px;
}

.barBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--blue-200);
  border-radius: 60px;
}

.textContainer {
  text-align: right;
  margin-left: 16px;
  width: 86px;
}
