.bodyWrapper {
  display: flex;
  flex-direction: column;
  width: 480px;
  padding: 24px 0;
  background: var(--white);

  .contentWrapper {
    padding: 8px 24px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--grey-300);
      border-radius: 20px;

      &:hover {
        background: var(--grey-400);
        background-clip: padding-box;
      }
    }
  }

  .hasScroll {
    padding: 8px 19px 8px 24px;
  }

  .footerWrapper {
    padding: 24px 24px 0;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 8px;
  }
}
