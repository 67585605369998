.wrapper {
  padding: 0 24px;

  .titleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .backButton {
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      padding: 0;
    }

    .hover {
      color: var(--grey-400);

      &:hover {
        color: var(--grey-500);
        cursor: pointer;
      }
    }
  }

  .marginBottom {
    padding-bottom: 24px;
  }

  .titleMarginTop {
    padding-top: 32px;
  }
}

.scrollShadow {
  box-shadow: 0 2px 8px 0 #0823300A;
}
