.iconWithBadge {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: fit-content;

  .badge {
    width: 6px;
    height: 6px;
    color: var(--pink-500);
    position: absolute;
    top: -12px;
    right: 0;
  }
}
