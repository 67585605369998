.errorChip {
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: var(--pink-500-08);
  border-radius: 2px;
  width: fit-content;
}

.content {
  color: var(--pink-600);
}

.icon {
  margin-right: 4px;
}

.button {
  margin-left: 8px;
}
