.wrapper {
  display: flex;
  flex-direction: row;
}

.dropdown {
  button {
    border-color: var(--grey-300);
    color: var(--grey-500) !important;
    font-weight: 400;
    text-transform: none;
    letter-spacing: initial;
    padding-left: 16px;
    padding-right: 5px;
    height: 40px;
    min-width: 140px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:hover, &:focus {
      background: transparent;
      border-color: var(--grey-400);
    }
  }

  span {
    flex-flow: row-reverse;
    justify-content: space-between;
  }
}

.inputWrapper {
  div {
    fieldset {
      border-color: var(--grey-300);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: unset;
    }

    input {
      text-overflow: ellipsis;
    }
  }
}

.focused {
  button {
    border-left: 1px var(--grey-400) solid;
  }
}
