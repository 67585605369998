.imageWrapper {
  position: relative;

  .removeButton {
    padding: 0;
    height: 24px;
    width: 24px;
    position: absolute;
    top: -12px;
    right: -12px;
    background: none;
    outline: none;
    border: none;
    color: var(--grey-500);

    &:hover {
      cursor: pointer;
      color: var(--pink-500);
    }
  }
}
