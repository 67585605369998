.base {
  display: inline-block;
  width: 24px;
  height: 24px;
  color: inherit;
}

.parentSize {
  height: 100%;
  width: 100%;
}

.defaultPickupPoint {
  color: var(--pink-500);
}

.lifeIcon24 {
  color: #052992;
}

.viberIcon24 {
  color: #7360F2;
}

.autoPartsIcon24,
.postPickupPoint {
  color: #0077CC;
}
