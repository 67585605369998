.bodyWrapper {
  display: flex;
  flex-direction: column;
  width: 480px;
  padding: 24px 0;
  max-height: 80vh;
  height: fit-content;
  background: var(--white);
  border-radius: 4px;
  margin-top: 10vh;

  .bodyTitleWrapper {
    padding: 0 24px;
    margin-bottom: 8px;
  }

  .bodyContentWrapper {
    flex: 1;
    overflow-y: auto;
    padding: 0 24px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--grey-300);
      border-radius: 20px;

      &:hover {
        background: var(--grey-400);
        background-clip: padding-box;
      }
    }
  }

  .bodyBtnWrapper {
    padding: 0 24px;
    margin-top: 24px;
    display: flex;
    justify-content: end;
    gap: 8px;
  }
}

.bodyWrapperMedium {
  width: 560px;
}
