.dropzoneWrapper {
  display: flex;
  align-items: center;
  padding: 16px;
  justify-content: center;
  border: 1px dashed var(--grey-400);
  background-color: var(--grey-100);
}

.dropzoneWrapperWithFile {
  background: var(--white);
}
