.wrapper {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;
}

.status {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

// Стили для кружка статуса
.greenStatus {
  background: var(--green-500);
}

.yellowStatus {
  background: var(--yellow-500);
}

.pinkStatus {
  background: var(--pink-500);
}

.grayStatus {
  background: var(--grey-400);
}

.blueStatus {
  background: var(--blue-500);
}

.grayOutlineStatus {
  outline: 1px solid var(--grey-500);
  background: transparent;
}

.magentaStatus {
  background: var(--magenta-500);
}

.lightBlueStatus {
  background: var(--blue-400);
}

.purpleStatus {
  background: var(--purple-500);
}

// Стили для текста
.blackText {
  color: var(--black-900);
}

.yellowText {
  color: var(--yellow-500);
}

.greenText {
  color: var(--green-500);
}

.pinkText {
  color: var(--pink-500);
}

.grayText {
  color: var(--grey-400);
}

.blueText {
  color: var(--blue-500);
}
