.footer {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-top: 8px;
  bottom: 0;
  background: var(--white);
  z-index: 10;
  position: sticky;
  width: 100%;
  border-top: 1px solid var(--grey-300);
}

.errorChip {
  margin-left: 24px;
  margin-right: 24px;
}

.leftActions {
  display: flex;

  button:not(:last-child) {
    margin-right: 8px;
  }
}

.rightActions {
  display: flex;

  button {
    padding: 8px;
    min-width: 32px;
    min-height: 32px;
  }
  button:not(:last-child) {
    margin-right: 8px;
  }
}
